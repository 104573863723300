import React from "react"
import Log from "./../../../templates/log.js"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Caption, FirstP, Emoji } from "./../../../components/helpers.js"

export default ({ data, pageContext }) => {

    const content = <>
        
        <FirstP>As discussed in the previous log I removed the ESP32 Pico from my design and replaced it by a conventional ESP32 module. Because I was not yet able to do a lot of testing on my first design, the rest of the PCB remained unchanged. The good news is that thanks to the easier assembly of this second iteration I finally got a working board. So let's see what we can learn from further testing.</FirstP>
        
        <Img fadeIn={false} fluid={data.img1.childImageSharp.fluid} alt="Final result" />
        <Caption>The ESP32 Pico is now replaced with a WROOM module</Caption>

        <h3>1. Overheating Linear Regulator</h3>
        <p>
            The first problem I encountered is related to the power supply on the board which is a linear regulator. Initially I did all my testin with a 12V supply, and that seems to work fine. 
            However, when I started testing at 14V which is more representative for the voltage in the car I regularly trigger the overtemperature protection of the LM2940, cutting power to the board.
            The board consumes around 200mA, which means that at 14V, 1.8W in heat must be dissipated <Emoji e="🔥" />. That is too much according to the datasheet.
        </p>

        <p>
            I quite like the regulator I picked for all its built-in safety functions. Nevertheless the most elegant solution would be to move to a switching regulator, because no matter what changes 
            I would make in a linear regulator design I will still need to dissipate all that power. Instead I will make a design with the LM2594-5 which seems to have the right specifications and does not 
            require too many external components. In any case the footprint will be larger due to the external inductor required.
        </p>

        <h3>2. Audio Connections</h3>

        <p>
            Something that I failed to do was look properly at the audio outputs of the CD Changer. Based on the pinout I deducted that I needed a differential audio signal
            but never checked the actual voltages, or voltage ranges. Rather stupid in hindsight <Emoji e="😅" />. What I did to test this was burn a CD (very nostalgic) 
            with a track that contained a sine wave at 100% volume. Turns out the CD changer gives a constant 2.5V on the negative terminal, 
            and on the positive terminal an audio signal between 1.9 and 3.1V.
        </p>

        <p>
            The good news is that the DAC that I chose is a perfect fit for this. It gives a positive and a negative audio signal which are both also centered around 2.5V. 
            There is also a pin which outputs the 2.5V common signal. The bad news is that I had routed the negative audio signals to the connector which is not correct. 
            I need to replace this so that the common signal will be used as V- for the audio. Also the maximum amplitude for the DAC is higher than that of the CD Changer. 
            For this I need to limit the volume in software to avoid noticable clipping.
        </p>

        <h3>3. Removing the DC/DC Converter</h3>

        <p>
            For this idea I still need to do the testing. But I feel that the isolated DC/DC converter might be overkill, because I don't think this method is used in commercial products.
            Therefore I want to try to remove this component and see if the audio quality is still ok. It should be since the audio is a differential signal anyway.
            This would also result in some free space on the board which can then nicely be used for the switching regulator.
        </p>

        <h3>Next Steps</h3>

        <p>
            All in all I am quite happy with this board. At 12V and using the right audio signals it has been playing for hours on an end in my desk setup. 
            The audio is nice and crisp without significant noise.
            Nevertheless a third design is needed, but you know what they say: third time's a charm!
            Finally, I also need to make some software improvements on the Bluetooth side to make sure that paired devices will be reconnected automatically.
        </p>
       
    </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}

export const query = graphql`
{
    img1: file(relativePath: { eq: "second.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`
